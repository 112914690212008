import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
import TrainCitySelector
  from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-city-selector/1.2.1/index.vue';
import Transfer from '@/page/admin/company-management/staff-management/component/transfer/index.vue'
import Calendar from '@/page/admin/company-management/staff-management/component/calendar/index.vue'
import CheckTravel from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/check-travel/1.0.0/index.vue'
import consumer_trains_product_checkTheRemainingTrainTickets
  from '@/lib/data-service/haolv-default/consumer_trains_product_checkTheRemainingTrainTickets'
import consumer_trains_common_queryRailwayTimetable
  from '@/lib/data-service/haolv-default/consumer_trains_common_queryRailwayTimetable'
import consumer_trains_order_scheduledToCheck
  from '@/lib/data-service/haolv-default/consumer_trains_order_scheduledToCheck'
import consumer_trains_order_addJourneyPC from '@/lib/data-service/haolv-default/consumer_trains_order_addJourneyPC'
import consumer_trains_order_addJourneyForPersonalPC
  from '@/lib/data-service/haolv-default/consumer_trains_order_addJourneyForPersonalPC'
import consumer_journey_getEvectionDetail from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail' // 获取出差信息
import consumer_department_getDepartmentUsers
  from "@/lib/data-service/haolv-default/consumer_department_getDepartmentUsers"; // 请求部门员工
import consumer_web_externalCustomer_getExternalCustomerAll
  from '@/lib/data-service/haolv-default/consumer_web_externalCustomer_getExternalCustomerAll' // 获取外部客户列表
import consumer_web_policyConfig_travelPolicyTips
  from '@/lib/data-service/haolv-default/consumer_web_policyConfig_travelPolicyTips' // 差标查询
import consumer_evection_addOrUpdate from '@/lib/data-service/haolv-default/consumer_evection_addOrUpdate' // 新增或修改出差单
import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'
import consumer_journey_addOrUpdateOverproof
  from '@/lib/data-service/haolv-default/consumer_journey_addOrUpdateOverproof' // 创建超标订单
import consumer_tOdHotelOrderInterim_updateCheckInPerson
  from '@/lib/data-service/haolv-default/consumer_tOdHotelOrderInterim_updateCheckInPerson' // 重新安排酒店房间入住
import consumer_trains_order_orderScheduledCheck
  from '@/lib/data-service/haolv-default/consumer_trains_order_orderScheduledCheck' // 校验超标
import aes_decrypt from 'nllib/src/common/aes/aes_decrypt';
import getEvectionAllType from '@/lib/common-service/getEvectionInfo'
import CitySelector
  from "yinzhilv-js-sdk/frontend/common-project/component/haolv/domestic-ticket-airport-selector/1.2.2/index.vue";
import moment from "moment";
import EvectionStandard from '@/component/evectionStandard/import_latest_version_module'
import EvectionStandardExceed from '@/component/evectionStandardExceed/import_latest_version_module'
import getJourneyOptionsCanBeModified
  from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_journey_options_can_be_modified/index.js'
// import TravelPopup from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/travel-popup/1.0.0/index.vue'
import TravelPopup from '@/component/travel-popup/1.0.0/index.vue'
import aes_encrypt from "nllib/src/common/aes/aes_encrypt";
import consumer_t_tp_refund_change_policy_get
  from '@/lib/data-service/haolv-default/consumert_tp_refund_change_policyget'
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import TrainTimetable from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-timetable/1.0.0/index.vue'

import EpidemicPolicy from '@/page/admin/travel-book/train/component/epidemicPolicy/1.0.0/index.vue'
//获取12306登录账号信息
import consumer_trains_account_myAccount from "@/lib/data-service/haolv-default/consumer_trains_account_myAccount";
import AccountProcessStep from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/12306-account-process-step/12306-account-process/1.0.0/index.vue'
import {hidePolicyLabelCompanyIdList} from "@/lib/other/index.js";
import TrainOrderRulePopup from '@/page/admin/travel-book/train/component/orderRulePopup/1.0.0/index.vue'


export default {
  data() {
    return {
      evectionDetailType: '', // '001':因公出差带出差单, '002': 因公出差不带出差单, '101':因私出差
      // 根据ID判断是否显示差标信息的ID集合
      hidePolicyLabelCompanyIdList: hidePolicyLabelCompanyIdList,
      companyId: null,
      // 记录信息(调用出差组件时保存当前预订的信息)
      reserveData:{},
      // 车次票价--排序
      TicketPrice: 0,
      // 到达时间--排序
      ArrivalTime: 0,
      // 旅行耗时--排序
      TravelTime: 0,
      // 出发时间--排序
      DepartureTime: 0,
      // 展开、收起code
      openStowCode: null,
      paramsData: null,
      checkBoxSelected: [],
      modelSelected: [
        {
          switch: false,
          text: '不限',
          motorcycleType: 0
        },
        {
          switch: false,
          text: 'C/G高铁',
          motorcycleType: 1
        },
        {
          switch: false,
          text: 'D动车',
          motorcycleType: 2
        },
        {
          switch: false,
          text: '普通列车',
          motorcycleType: 3
        },
      ],
      setOutTimeSelected: [
        {
          switch: false,
          text: '不限',
          fromTimeCode: 4
        },
        {
          switch: false,
          text: '00:00 - 06:00',
          fromTimeCode: 0
        },
        {
          switch: false,
          text: '06:00 - 12:00',
          fromTimeCode: 1
        },
        {
          switch: false,
          text: '12:00 - 18:00',
          fromTimeCode: 2
        },
        {
          switch: false,
          text: '18:00 - 24:00',
          fromTimeCode: 3
        },
      ],
      arriveTimeSelected: [
        {
          switch: false,
          text: '不限',
          toTimeCode: 4
        },
        {
          switch: false,
          text: '00:00 - 06:00',
          toTimeCode: 0
        },
        {
          switch: false,
          text: '06:00 - 12:00',
          toTimeCode: 1
        },
        {
          switch: false,
          text: '12:00 - 18:00',
          toTimeCode: 2
        },
        {
          switch: false,
          text: '18:00 - 24:00',
          toTimeCode: 3
        },
      ],
      setOutStationSelected: [],
      arriveStationSelected: [],
      Originating: [
        {
          switch: false,
          text: '不限',
          toStationType: 0
        },
        {
          switch: false,
          text: '始发',
          toStationType: 1
        },
        {
          switch: false,
          text: '路过',
          toStationType: 2
        },
      ],
      cityCanEdit: null,
      timeCanEdit: null,
      checked: false,
      loading: false,
      redirectType: '',
      evectionNo: '', // 出差预定的id
      evectionType: 1, // 1为个人出差， 2为出差单出差
      applyId: 0,   //申请单id
      orderNo: '',
      initStatus: 0,
      pickerOptions: {
        disabledDate: this.disabledDate
      },
      fromStation: {},
      toStation: {},
      oriFromStation: {},
      oriToStation: {},
      params: {
        fromStationCode: '',
        toStationCode: '',
        trainDate: ''
      },
      pickerTrainDate: [],
      pickerTrainDateTo: [],
      saveWeek: '',
      oriTrainDate: '',
      isAllTrain: true, // 是否搜索全部类型
      onlySearchTrain: false, // 是否只搜高铁列车
      checkTrainList: [],
      showLoading: false, // loading时是否显示10分钟刷新的提示
      trainList: [],
      trainListAll: [],
      daySpacing: '',
      isHasTicket: false, // 是否只显示有票
      reloadTicketFun: '',
      reloadNum: 0,
      loadingNum: 0,

      currentTrainInfo: {}, // 当前选定订票的火车票信息

      fromStationNum: 0,
      toStationNum: 0,

      userInfo: {
        realName: ''
      },
      travelCurrent: null, // 出差当前预设
      travelLVisible: false, // 出行人选择弹窗 -- 选择了出差单
      popSelectTravelUserList: [], // 出行人列表
      travelUserCheckList: [], // 选中的出行人
      travelLVisible2: false, // 出行人选择弹窗 -- 没选择出差单
      cascadeList: [], // 员工列表
      currentActiveList: [], //员工当前选中列表
      travelPolicy: '', // 差标标准
      evectionParams: {
        travelUserList: []
      },

      // 出差信息
      evectionInfo: {},
      ticketTrainList: [],
      flightList: [],
      hotelList: [],
      travelUserList: [],

      newTravelUser: [],
      pendingTravelUserList: [], // 待定的出行人列表

      hotelUserVisible: false,
      hotelForm: {
        roomNum: 0,
        checkInPerson: []
      },
      hotelRules: {},
      hotelUserList: [],
      loades: 10,
      query: {},
      timeData: [],
      trainOrderNo: null,
      application: {
        startStationList: [],
        endStationList: [],
      },
      //出差规则
      // 是否不限行程 0：否 1：是
      unlimitedJourney: 1,
      // 是否允许修改行程时间 0：否 1：是
      allowChangeJourneyTime: 0,
      // 帮订人数上限(为1表示只能帮自己预定)
      bookUpperBound: 1,
      // 因公出差规则 1：出差单审批 2：预订审批
      businessRuleControl: 0,
      // 是否可选临近城市 0：否 1：是
      changeEvectionCity: 0,
      // 全局改签政策：0:不限, 2:走审批流
      changePolicyType: 0,
      // 出差单是否必填：0:否, 1:是
      evectionRequired: 0,
      //是否长-短期出差标准 0：否 1：是
      ifLongShortTrip: 0,
      //保险管控 1：企业支付 2：个人支付
      insuranceControl: 0,
      // 多人出行差标 1：最低 2：最高
      policyControlRule: 0,
      // 全局退票政策：0:不限, 2:走审批流
      refundPolicyType: 0,
      // 是否不限行程 0：否 1：是
      // nlimitedJourney: 0,
      //是否已选择出差单
      workTravelType: 1,
      journeyId: 0,
      currentPolicyIndex: null,

      orderRuleDialogVisible: false,
    }
  },
  components: {
    TrainOrderRulePopup,
    Transfer,
    Calendar,
    TrainCitySelector,
    CheckTravel,
    CitySelector,
    EvectionStandard,
    EvectionStandardExceed,
    TravelPopup,
    TrainTimetable,
    EpidemicPolicy,
    AccountProcessStep
  },
  created() {
  },
  mounted() {
    this.$refs.formTrainCitySelector.init({
      placeholder_text: `请选择出发城市`,
    });
    this.canChangeFactorBox();
    this.workTravelType = this.$route.query.workTravelType;
    this.journeyId = this.$route.query.journeyId;
  },
  async activated() {
    this.currentPolicyIndex = null;
    //信息回填完毕，调用接口请求数据
    await this.refundChangePolicyGet();
    this.initPage();
    this.getTravelRule();
    document.documentElement.scrollTop = 0;
  },
  deactivated() {
    clearInterval(this.reloadTicketFun);
  },
  destroyed() {
    clearInterval(this.reloadTicketFun);
  },
  watch: {
    fromStation(newVal, oldVal) {
      if (newVal === null) {
        this.params.fromStationCode = '';
      } else {
        this.params.fromStationCode = newVal.stationCode;
      }
      if (this.fromStationNum === 0 && newVal.stationCode !== undefined && newVal.stationName !== undefined) {
        this.oriFromStation = Object.assign({}, newVal);
        this.fromStationNum++;
      }
    },
    toStation(newVal, oldVal) {
      if (newVal === null) {
        this.params.toStationCode = '';
      } else {
        this.params.toStationCode = newVal.stationCode;
      }
      if (this.toStationNum === 0 && newVal.stationCode !== undefined && newVal.stationName !== undefined) {
        this.oriToStation = Object.assign({}, newVal);
        this.toStationNum++;
      }
    },
  },
  computed: {},
  filters: {},
  methods: {
    clickLieText(item){
        if (item.seatName.indexOf('卧') == -1) {
            return;
        }
        this.$alert('展示的票价为上铺成人票价，实际以占座成功的价格为准', '温馨提示', {
            confirmButtonText: '我知道了',
            callback: action => {

            }
        });
    },
    setSort() {
      const __this = this;
      let trainList = __this.trainList;

      let timeFormMinute = function (val) {
        let timeVal = val.split(':');
        let hour = parseInt(timeVal[0]) * 60;
        let minute = parseInt(timeVal[1]);
        return hour + minute
      };
      let timeToMinute = function (val) {
        let timeVal = val.toTime.split(':');
        let hour = timeVal[0] = (Number(timeVal[0]) +  Number(val.daysTrip*24)) * 60;
        let minute = Number(parseInt(timeVal[1]));
        return hour + minute
      };
      let DepartureTimeJust = function (x, y) { // 按出发时间正序排列
        let xFromTime = timeFormMinute(x.fromTime);
        let yFromTime = timeFormMinute(y.fromTime);
        if (xFromTime < yFromTime) {
          return -1;
        } else if (xFromTime > yFromTime) {
          return 1;
        } else {
          return 0;
        }
      };
      let DepartureTimeBack = function (x, y) { // 按出发时间反序排列
        let xFromTime = timeFormMinute(x.fromTime);
        let yFromTime = timeFormMinute(y.fromTime);
        if (xFromTime > yFromTime) {
          return -1;
        } else if (xFromTime < yFromTime) {
          return 1;
        } else {
          return 0;
        }
      };
      let TravelTimeJust = function (x, y) { // 按出发时间排列短-->长
        let xBox = x.spanTime.split('时');
        let xSpanTime = 0;
        if (xBox.length == 2) {
          xSpanTime = xBox[0]*100 + Number(xBox[1].replace('分',''));
        }
        if (xBox.length == 1) {
          xSpanTime = Number(xBox[0].replace('分',''));
        }
        let yBox = y.spanTime.split('时');
        let ySpanTime = 0;
        if (yBox.length == 2) {
          ySpanTime = yBox[0]*100 + Number(yBox[1].replace('分',''));
        }
        if (yBox.length == 1) {
          ySpanTime = Number(yBox[0].replace('分',''));
        }
        if (xSpanTime < ySpanTime) {
          return -1;
        } else if (xSpanTime > ySpanTime) {
          return 1;
        } else {
          return 0;
        }
      };
      let TravelTimeBack = function (x, y) { // 按出发时间排列长-->短
        let xBox = x.spanTime.split('时');
        let xSpanTime = 0;
        if (xBox.length == 2) {
          xSpanTime = xBox[0]*100 + Number(xBox[1].replace('分',''));
        }
        if (xBox.length == 1) {
          xSpanTime = Number(xBox[0].replace('分',''));
        }
        let yBox = y.spanTime.split('时');
        let ySpanTime = 0;
        if (yBox.length == 2) {
          ySpanTime = yBox[0]*100 + Number(yBox[1].replace('分',''));
        }
        if (yBox.length == 1) {
          ySpanTime = Number(yBox[0].replace('分',''));
        }
        if (xSpanTime > ySpanTime) {
          return -1;
        } else if (xSpanTime < ySpanTime) {
          return 1;
        } else {
          return 0;
        }
      };
      let ArrivalTimeJust = function (x, y) { // 按到达时间正序排列
        let xToTime = timeToMinute(x);
        let yToTime = timeToMinute(y);
        if (xToTime < yToTime) {
          return -1;
        } else if (xToTime > yToTime) {
          return 1;
        } else {
          return 0;
        }
      };
      let ArrivalTimeBack = function (x, y) { // 按到达时间反序排列
        let xToTime = timeToMinute(x);
        let yToTime = timeToMinute(y);
        if (xToTime > yToTime) {
          return -1;
        } else if (xToTime < yToTime) {
          return 1;
        } else {
          return 0;
        }
      };
      let TicketPriceJust = function (x, y) { // 按车次票价正序排列
        let xNum = Number(x.trainSeatVoList[0].seatPrice);
        let yNum = Number(y.trainSeatVoList[0].seatPrice);
        if (xNum < yNum) {
          return -1;
        } else if (xNum > yNum) {
          return 1;
        } else {
          return 0;
        }
      };
      let TicketPriceBack = function (x, y) { // 按车次票价反序排列
        let xNum = Number(x.trainSeatVoList[0].seatPrice);
        let yNum = Number(y.trainSeatVoList[0].seatPrice);
        if (xNum > yNum) {
          return -1;
        } else if (xNum < yNum) {
          return 1;
        } else {
          return 0;
        }
      };

      if (this.DepartureTime != 0) {
        if (this.DepartureTime == 1) {
          trainList.sort(DepartureTimeJust);
        }
        if (this.DepartureTime == 2) {
          trainList.sort(DepartureTimeBack);
        }
      } else if (this.TravelTime != 0) {
        if (this.TravelTime == 1) {
          trainList.sort(TravelTimeJust);
        }
        if (this.TravelTime == 2) {
          trainList.sort(TravelTimeBack);
        }
      } else if (this.ArrivalTime != 0) {
        if (this.ArrivalTime == 1) {
          trainList.sort(ArrivalTimeJust);
        }
        if (this.ArrivalTime == 2) {
          trainList.sort(ArrivalTimeBack);
        }
      } else if (this.TicketPrice != 0) {
        if (this.TicketPrice == 1) {
          trainList.sort(TicketPriceJust);
        }
        if (this.TicketPrice == 2) {
          trainList.sort(TicketPriceBack);
        }
      }

      __this.trainList = trainList;
    },
    // 出发时间--排序--click
    DepartureTimeClick(){
      // 清除其他筛选条件
      this.TravelTime = 0;
      this.ArrivalTime = 0;
      this.TicketPrice = 0;
      if (this.DepartureTime == 0) {
        this.DepartureTime = 1;
        this.setSort();
        return
      }
      if (this.DepartureTime == 1) {
        this.DepartureTime = 2;
        this.setSort();
        return
      }
      if (this.DepartureTime == 2) {
        this.DepartureTime = 1;
        this.setSort();
        return
      }
    },
    // 旅行耗时--排序--click
    TravelTimeClick(){
      // 清除其他筛选条件
      this.DepartureTime = 0;
      this.ArrivalTime = 0;
      this.TicketPrice = 0;
      if (this.TravelTime == 0) {
        this.TravelTime = 1;
        this.setSort();
        return
      }
      if (this.TravelTime == 1) {
        this.TravelTime = 2;
        this.setSort();
        return
      }
      if (this.TravelTime == 2) {
        this.TravelTime = 1;
        this.setSort();
        return
      }
    },
    // 到达时间--排序--click
    ArrivalTimeClick(){
      // 清除其他筛选条件
      this.DepartureTime = 0;
      this.TravelTime = 0;
      this.TicketPrice = 0;
      if (this.ArrivalTime == 0) {
        this.ArrivalTime = 1;
        this.setSort();
        return
      }
      if (this.ArrivalTime == 1) {
        this.ArrivalTime = 2;
        this.setSort();
        return
      }
      if (this.ArrivalTime == 2) {
        this.ArrivalTime = 1;
        this.setSort();
        return
      }
    },
    // 车次票价--排序--click
    TicketPriceClick(){
      // 清除其他筛选条件
      this.DepartureTime = 0;
      this.TravelTime = 0;
      this.ArrivalTime = 0;
      if (this.TicketPrice == 0) {
        this.TicketPrice = 1;
        this.setSort();
        return
      }
      if (this.TicketPrice == 1) {
        this.TicketPrice = 2;
        this.setSort();
        return
      }
      if (this.TicketPrice == 2) {
        this.TicketPrice = 1;
        this.setSort();
        return
      }
    },
    // 展开、收起方法
    openStowClick(index){
      if (this.openStowCode == index) {
        this.openStowCode = null;
      } else {
        this.openStowCode = index;
      }
    },



    async refundChangePolicyGet() {
      let res = await refund_change_policy_get({clear_cache: false});
      this.evectionRequired = res.datas.evectionRequired;
      this.bookUpperBound = res.datas.bookUpperBound;
      this.allowChangeJourneyTime = res.datas.allowChangeJourneyTime;
      this.unlimitedJourney = res.datas.unlimitedJourney;
      this.changeEvectionCity = res.datas.changeEvectionCity;
      this.businessRuleControl = res.datas.businessRuleControl;
    },
    changeDepDate(val) {
      //将去程选择的日期录入query中
      let query = this.$route.query;
      const key = 'params';
      const params = aes_decrypt({
        data: query.params,
        key,
      });
      query = Object.assign({}, query, JSON.parse(params));
      query.time = val;

      const encrypted = aes_encrypt({
        data: JSON.stringify(query),
        key,
      });

      this.$route.query.params = encrypted;

    },
    getTravelRule() {
      consumer_t_tp_refund_change_policy_get().then(res => {
        this.allowChangeJourneyTime = res.datas.allowChangeJourneyTime;
        this.bookUpperBound = res.datas.bookUpperBound;
        this.businessRuleControl = res.datas.businessRuleControl;
        this.changeEvectionCity = res.datas.changeEvectionCity;
        this.changePolicyType = res.datas.changePolicyType;
        this.evectionRequired = res.datas.evectionRequired;
        this.hotelLocationPolicy = res.datas.hotelLocationPolicy;
        this.ifLongShortTrip = res.datas.ifLongShortTrip;
        this.insuranceControl = res.datas.insuranceControl;
        this.policyControlRule = res.datas.policyControlRule;
        this.refundPolicyType = res.datas.refundPolicyType;
        this.unlimitedJourney = res.datas.unlimitedJourney;
      })
    },
    updateEvectionInfo(val) {
      //editType: string, 编辑类型，
      // '000' 变更出差单号(无出差单->有出差单 或者 有出差单->有出差单),
      // '001': 变更出差单（有出差单->无出差单）
      // '002' 修改原本出差单内容
      // 原地刷新、返回列表页面
      if (val.editType === '002') {
        this.evectionNo = val.evectionNo;
        this.journeyId = val.journeyId;
        this.initPage()
      } else if (val.editType === '001') {
        let queryParams = {
          fromStationCode: this.query.fromStationCode,
          toStationCode: this.query.toStationCode,
          time: this.query.time,
          onlySearch: this.query.onlySearch,
          applyId: this.query.applyId,
          redirectType: '000',
          journeyId: this.query.journeyId,
          startRangeDate: this.query.startRangeDate,
          endRangeDate: this.query.endRangeDate,
          activeChooseUserList: this.query.activeChooseUserList,
        };
        const encrypted = aes_encrypt({
          data: JSON.stringify(queryParams),
          key: 'params',
        });
        this.$router.push({
          name: 'admin-train-search',
          query: {
            params: encrypted,
            evectionNo: this.query.evectionNo,
            evectionType: this.query.evectionType,
            evectionRequired: this.query.evectionRequired,
            workTravelType: this.query.workTravelType,
            journeyType: this.query.journeyType,
            journeyId: this.query.journeyId,
          }
        });
      } else if (val.editType === '000') {
        let activeChooseUserList = [];
        val.travelCurrent.active.staffList.forEach((item, index) => {
          activeChooseUserList.push(item.userId);
        });
        let queryParams = {
          fromStationCode: val.travelCurrent.active.departCityId || this.fromStation.stationCode,
          toStationCode: val.travelCurrent.active.toCityId || this.toStation.stationCode,
          time: val.travelCurrent.active.departTime,
          onlySearch: this.paramsData.onlySearch,
          applyId: val.travelCurrent.active.evectionId,
          redirectType: this.paramsData.redirectType,
          journeyId: val.journeyId,
          startRangeDate: val.travelCurrent.active.departTime,
          endRangeDate: val.travelCurrent.active.departTimeEnd,
          activeChooseUserList,
        };
        const encrypted = aes_encrypt({
          data: JSON.stringify(queryParams),
          key: 'params',
        });
        this.$router.push({
          name: 'admin-train-search',
          query: {
            params: encrypted,
            workTravelType: val.workTravelType,
            evectionNo: val.evectionNo,
            evectionType: this.$route.query.evectionType,
            evectionRequired: this.$route.query.evectionRequired,
          }
        })
      }
    },
    editEvection(popupType,reserveItem,reserveIndex,reserveTIndex) {
      this.currentPolicyIndex = null;
      if (reserveItem.trainSeatVoList[reserveTIndex].bookStatus !== 1) {
        this.currentPolicyIndex = [reserveIndex,reserveTIndex];
        this.reserveData = {
          item: reserveItem,
          index: reserveIndex,
          TIndex: reserveTIndex,
        };
        this.$refs['travelPopup'].init({
          popupType,
          evectionNo: this.evectionNo,
          evectionRequired: Number(this.$route.query.evectionRequired),
          journeyId: Number(this.journeyId),
          businessRuleControl: this.businessRuleControl,
        })
      }
    },
    toEditEvectionInfo(popupType) {
      this.currentPolicyIndex = null;
      this.$refs['travelPopup'].init({
        popupType,
        evectionNo: this.evectionNo,
        evectionRequired: Number(this.$route.query.evectionRequired),
        journeyId: Number(this.journeyId),
        businessRuleControl: this.businessRuleControl,
      })
    },
    async initPage() {
      let query = this.$route.query;
      const key = 'params';
      const params = aes_decrypt({
        data: query.params,
        key,
      });
      query = Object.assign({}, query, JSON.parse(params));

      //判断出差单类型
      let data = {
        vm: this,
      };
      const evectionQuery = getEvectionAllType(data);
      //有出差单限制出发地目的地
      this.evectionDetailType = evectionQuery.evectionDetailType;
      //有出差单限制时间
      this.timeData[0] = query.startRangeDate;
      this.timeData[1] = query.endRangeDate;

      this.query = query;
      this.redirectType = this.query.redirectType;
      this.evectionNo = this.query.evectionNo ? this.query.evectionNo : '';
      this.applyId = this.query.applyId ? parseInt(this.query.applyId) : 0;
      if (this.redirectType === '002') {
        this.orderNo = this.query.orderNo;
      }
      if (this.query.fromStationCode) {
        this.fromStation = {
          stationCode: this.query.fromStationCode
        }
      } else {
        this.fromStation = {}
      }
      if (this.query.toStationCode) {
        this.toStation = {
          stationCode: this.query.toStationCode
        }
      } else {
        this.toStation = {}
      }
      this.evectionType = parseInt(this.query.evectionType);
      this.params.fromStationCode = this.query.fromStationCode ? this.query.fromStationCode : '';
      this.params.toStationCode = this.query.toStationCode ? this.query.toStationCode : '';
      this.params.trainDate = this.query.time ? this.query.time : this.$moment().locale('zh-cn').format('YYYY-MM-DD');
      this.oriFromStation = Object.assign({}, this.fromStation);
      this.oriToStation = Object.assign({}, this.toStation);
      this.oriTrainDate = this.params.trainDate;
      this.pickerTrainDate = this.query.time ? this.query.time : this.$moment().locale('zh-cn').format('YYYY-MM-DD');
      //去程时间
      this.pickerTrainDateTo = query.time;
      this.saveWeek = this.pickerTrainDateTo;


      let onlySearch = parseInt(this.query.onlySearch); // 是否只搜动车
      if (onlySearch === 1) {
        this.isAllTrain = false;
        this.onlySearchTrain = true;
        this.checkTrainList = [1, 2]; // 传入动车的数值
      }
      const paramsData = JSON.parse(aes_decrypt({
        data: this.$route.query.params,
        key: 'params',
      }));
      this.paramsData = paramsData;
      await this.getTrainList(true);
      await this.get_travelPolicyTips();
      await this.reloadTicket();

      await this.getTravelInfoAndUserList(); // 我的出差
      await this.getDepartmentUser(); // 获取所有员工
      await this.getEvectionInfo(); // 获取出差信息
    },
    clickDelete(item, index) {
      this.checkBoxSelected.splice(index, 1);
      if (item.motorcycleType != null || item.text == '到达时段：不限') {
        this.modelSelected.forEach((CItem, CIndex) => {
          if (item.motorcycleType == CItem.motorcycleType) {
            CItem.switch = false;
          }
        })
      } else if (item.fromTimeCode != null || item.text == '出发时段：不限') {
        this.setOutTimeSelected.forEach((CItem, CIndex) => {
          if (item.fromTimeCode == CItem.fromTimeCode) {
            CItem.switch = false;
          }
        })
      } else if (item.toTimeCode != null || item.text == '车型：不限') {
        this.arriveTimeSelected.forEach((CItem, CIndex) => {
          if (item.toTimeCode == CItem.toTimeCode) {
            CItem.switch = false;
          }
        })
      } else if (item.fromStationCode != null || item.text == '出发车站：不限') {
        this.setOutStationSelected.forEach((CItem, CIndex) => {
          if (item.fromStationCode == CItem.fromStationCode) {
            CItem.switch = false;
          }
        })
      } else if (item.toStationCode != null || item.text == '到达车站：不限') {
        this.arriveStationSelected.forEach((CItem, CIndex) => {
          if (item.toStationCode == CItem.toStationCode) {
            CItem.switch = false;
          }
        })
      } else if (item.toStationType != null || item.text == '是否始发站：不限') {
        this.Originating.forEach((CItem, CIndex) => {
          if (item.toStationType == CItem.toStationType) {
            CItem.switch = false;
          }
        })
      }
      this.screenMethod();
    },
    checkBoxOriginating(data) {
      //循环数据，进行操作
      this.Originating.forEach((item, index) => {
        if (data.toStationType == item.toStationType) {
          if (data.switch) {
            this.checkBoxSelected.push({
              text: `是否始发站：${item.text}`,
              motorcycleType: null,
              fromTimeCode: null,
              toTimeCode: null,
              fromStationCode: null,
              toStationCode: null,
              toStationType: item.toStationType,
            });
          } else if (!data.switch) {
            this.checkBoxSelected.forEach((CItem, CIndex) => {
              if (CItem.text == `是否始发站：${item.text}`) {
                this.checkBoxSelected.splice(CIndex, 1);
              }
            });
          }
        } else {
          item.switch = false;
          this.checkBoxSelected.forEach((CItem, CIndex) => {
            if (CItem.text == `是否始发站：${item.text}`) {
              this.checkBoxSelected.splice(CIndex, 1);
            }
          });
        }
      });
      this.screenMethod();
    },
    checkBoxArriveStation(data) {
      if (data.switch) {
        this.checkBoxSelected.push({
          text: `到达车站：${data.text}`,
          motorcycleType: null,
          fromTimeCode: null,
          toTimeCode: null,
          fromStationCode: null,
          toStationCode: data.toStationCode,
          toStationType: null,
        });
      } else {
        this.checkBoxSelected.forEach((item, index) => {
          if (item.text == `到达车站：${data.text}`) {
            this.checkBoxSelected.splice(index, 1);
          }
        });
      }
      if (data.text == '不限') {
        let box = [];
        this.arriveStationSelected.forEach((item, index) => {
          if (index != 0) {
            item.switch = false;
          }
        });
        this.checkBoxSelected.forEach((CItem, CIndex) => {
          if (CItem.toStationCode == null) {
            box.push(CItem);
          }
        });
        this.checkBoxSelected = box;
      } else {
        this.arriveStationSelected[0].switch = false;
        this.checkBoxSelected.forEach((item, index) => {
          if (item.text == `到达车站：不限`) {
            this.checkBoxSelected.splice(index, 1);
          }
        });
      }
      this.screenMethod();
    },
    checkBoxSetOutStation(data) {
      if (data.switch) {
        this.checkBoxSelected.push({
          text: `出发车站：${data.text}`,
          motorcycleType: null,
          fromTimeCode: null,
          toTimeCode: null,
          fromStationCode: data.fromStationCode,
          toStationCode: null,
          toStationType: null,
        });
      } else {
        this.checkBoxSelected.forEach((item, index) => {
          if (item.text == `出发车站：${data.text}`) {
            this.checkBoxSelected.splice(index, 1);
          }
        });
      }
      if (data.text == '不限') {
        let box = [];
        this.setOutStationSelected.forEach((item, index) => {
          if (index != 0) {
            item.switch = false;
          }
        });
        this.checkBoxSelected.forEach((CItem, CIndex) => {
          if (CItem.fromStationCode == null) {
            box.push(CItem);
          }
        });
        this.checkBoxSelected = box;
      } else {
        this.setOutStationSelected[0].switch = false;
        this.checkBoxSelected.forEach((item, index) => {
          if (item.text == `出发车站：不限`) {
            this.checkBoxSelected.splice(index, 1);
          }
        });
      }
      this.screenMethod();
    },
    checkBoxArriveTime(data) {
      if (data.switch) {
        this.checkBoxSelected.push({
          text: `到达时段：${data.text}`,
          motorcycleType: null,
          fromTimeCode: null,
          toTimeCode: data.toTimeCode,
          fromStationCode: null,
          toStationCode: null,
          toStationType: null,
        });
      } else {
        this.checkBoxSelected.forEach((item, index) => {
          if (item.text == `到达时段：${data.text}`) {
            this.checkBoxSelected.splice(index, 1);
          }
        });
      }
      if (data.text == '不限') {
        let box = [];
        this.arriveTimeSelected.forEach((item, index) => {
          if (index != 0) {
            item.switch = false;
          }
        });
        this.checkBoxSelected.forEach((CItem, CIndex) => {
          if (CItem.toTimeCode == null) {
            box.push(CItem);
          }
        });
        this.checkBoxSelected = box;
      } else {
        this.arriveTimeSelected[0].switch = false;
        this.checkBoxSelected.forEach((item, index) => {
          if (item.text == `到达时段：不限`) {
            this.checkBoxSelected.splice(index, 1);
          }
        });
      }
      this.screenMethod();
    },
    checkBoxSetOutTime(data) {
      if (data.switch) {
        this.checkBoxSelected.push({
          text: `出发时段：${data.text}`,
          motorcycleType: null,
          fromTimeCode: data.fromTimeCode,
          toTimeCode: null,
          fromStationCode: null,
          toStationCode: null,
          toStationType: null,
        });
      } else {
        this.checkBoxSelected.forEach((item, index) => {
          if (item.text == `出发时段：${data.text}`) {
            this.checkBoxSelected.splice(index, 1);
          }
        });
      }
      if (data.text == '不限') {
        let box = [];
        this.setOutTimeSelected.forEach((item, index) => {
          if (index != 0) {
            item.switch = false;
          }
        });
        this.checkBoxSelected.forEach((CItem, CIndex) => {
          if (CItem.fromTimeCode == null) {
            box.push(CItem);
          }
        });
        this.checkBoxSelected = box;
      } else {
        this.setOutTimeSelected[0].switch = false;
        this.checkBoxSelected.forEach((item, index) => {
          if (item.text == `出发时段：不限`) {
            this.checkBoxSelected.splice(index, 1);
          }
        });
      }
      this.screenMethod();
    },
    checkBoxModel(data) {
      //区分选中和取消状态
      if (data.switch) {
        //选中操作--录入信息进行已选数据回显
        this.checkBoxSelected.push({
          text: `车型：${data.text}`,
          motorcycleType: data.motorcycleType,
          fromTimeCode: null,
          toTimeCode: null,
          fromStationCode: null,
          toStationCode: null,
          toStationType: null,
        });
      } else {
        //取消操作--删除数据
        this.checkBoxSelected.forEach((item, index) => {
          if (item.text == `车型：${data.text}`) {
            this.checkBoxSelected.splice(index, 1);
          }
        });
      }
      //不限选项操作
      if (data.text == '不限') {
        let box = [];
        //将不限以外选中取消选中
        this.modelSelected.forEach((item, index) => {
          if (index != 0) {
            item.switch = false;
          }
        });
        //将不限选项信息暂时保存
        this.checkBoxSelected.forEach((CItem, CIndex) => {
          if (CItem.motorcycleType == null) {
            box.push(CItem);
          }
        });
        //录入不限选项信息进行回显
        this.checkBoxSelected = box;
      } else {
        //将不限选项取消选中
        this.modelSelected[0].switch = false;
        //删除不限选项信息
        this.checkBoxSelected.forEach((item, index) => {
          if (item.text == `车型：不限`) {
            this.checkBoxSelected.splice(index, 1);
          }
        });
      }
      //进行筛选
      this.screenMethod();
    },
    screenMethod() {
      let data = this.trainListAll;
      let box = [];
      let motorcycleTypeBox = [];
      let fromTimeCode = [];
      let toTimeCode = [];
      let fromStationCode = [];
      let toStationCode = [];
      let toStationType = [];

      this.checkBoxSelected.forEach((item, index) => {
        if (item.text == '到达时段：不限') {
          data.forEach((CItem, CIndex) => {
            motorcycleTypeBox.push(CItem);
          });
        } else if (item.motorcycleType != null) {
          data.forEach((CItem, CIndex) => {
            if (CItem.motorcycleType == item.motorcycleType) {
              motorcycleTypeBox.push(CItem);
            }
          });
        }
        if (item.text == '出发时段：不限') {
          data.forEach((CItem, CIndex) => {
            fromTimeCode.push(CItem);
          });
        } else if (item.fromTimeCode != null) {
          data.forEach((CItem, CIndex) => {
            if (CItem.fromTimeCode == item.fromTimeCode) {
              fromTimeCode.push(CItem);
            }
          });
        }
        if (item.text == '车型：不限') {
          data.forEach((CItem, CIndex) => {
            toTimeCode.push(CItem);
          });
        } else if (item.toTimeCode != null) {
          data.forEach((CItem, CIndex) => {
            if (CItem.toTimeCode == item.toTimeCode) {
              toTimeCode.push(CItem);
            }
          });
        }
        if (item.text == '出发车站：不限') {
          data.forEach((CItem, CIndex) => {
            fromStationCode.push(CItem);
          });
        } else if (item.fromStationCode != null) {
          data.forEach((CItem, CIndex) => {
            if (CItem.fromStationCode == item.fromStationCode) {
              fromStationCode.push(CItem);
            }
          });
        }
        if (item.text == '到达车站：不限') {
          data.forEach((CItem, CIndex) => {
            toStationCode.push(CItem);
          });
        } else if (item.toStationCode != null) {
          data.forEach((CItem, CIndex) => {
            if (CItem.toStationCode == item.toStationCode) {
              toStationCode.push(CItem);
            }
          });
        }
        if (item.text == '是否始发站：不限') {
          data.forEach((CItem, CIndex) => {
            toStationType.push(CItem);
          });
        } else if (item.toStationType != null) {
          data.forEach((CItem, CIndex) => {
            // console.log('CItem',CItem.toStationType,'item',item.toStationType);
            if (
              (CItem.toStationType == 1 && item.toStationType == 1) ||
              (CItem.toStationType == 2 && item.toStationType == 2) ||
              item.toStationType == 0
            ) {
              toStationType.push(CItem);
            }
          });
        }
      });

      this.checkBoxSelected.forEach((item, index) => {
        if (item.motorcycleType != null || item.text == '到达时段：不限') {
          box.push(motorcycleTypeBox);
        }
        if (item.fromTimeCode != null || item.text == '出发时段：不限') {
          box.push(fromTimeCode);
        }
        if (item.toTimeCode != null || item.text == '车型：不限') {
          box.push(toTimeCode);
        }
        if (item.fromStationCode != null || item.text == '出发车站：不限') {
          box.push(fromStationCode);
        }
        if (item.toStationCode != null || item.text == '到达车站：不限') {
          box.push(toStationCode);
        }
        if (item.toStationType != null || item.text == '是否始发站：不限') {
          box.push(toStationType);
        }
      });

      let obj = {};
      let dataBox = [];
      box.forEach((item, index) => {
        if (!box[index - 1]) {
          dataBox = box[index];
        } else {
          const test = box[index - 1].map(item => item.trainNo);
          dataBox = box[index].filter(item => test.includes(item.trainNo));
        }
      });

      if (this.checkBoxSelected.length == 0) {
        this.trainList = this.trainListAll;
      } else {
        this.trainList = dataBox;
      }

    },
    //调用方法判断当前状态下地点、时间是否可以改变
    canChangeFactorBox() {
      getJourneyOptionsCanBeModified({
        situation: '000',
        vm: this
      }).then(res => {
        this.cityCanEdit = res.cityCanEdit;
        this.timeCanEdit = res.timeCanEdit;
      });
    },
    setStandard() {
      if (!this.$refs.standard) {
        setTimeout(() => {
          this.setStandard()
        }, 500);
        return
      }
      this.$refs.standard.init({
        evectionNo: this.evectionNo,
        businessType: 2,
        evectionRequired: this.$route.query.evectionRequired,
        businessRuleControl: this.businessRuleControl
      })
    },
    filterWeek(val) {
      if (val === '' || !val) {
        return ''
      }
      let day = moment(val).day();
      if (day === 0) {
        return '周日'
      } else if (day === 1) {
        return '周一'
      } else if (day === 2) {
        return '周二'
      } else if (day === 3) {
        return '周三'
      } else if (day === 4) {
        return '周四'
      } else if (day === 5) {
        return '周五'
      } else if (day === 6) {
        return '周六'
      } else {
        return ''
      }
    },
    // 获取当前出差单信息和可选择的出行人列表
    getTravelInfoAndUserList() {
      if (this.evectionType === 1 || this.evectionType === '1') return;
      const params = {
        evectionNo: this.evectionNo,
        approveType: 1,
      };
    },
    // 获取出差信息
    async getEvectionInfo() {
      if (this.evectionType === 1 || this.evectionType === '1') return;
      const [err, res] = await awaitWrap(consumer_journey_getEvectionDetail({
        evectionNo: this.evectionNo,
        pageType: 1,
        personType: 2
      }));
      if (err) {
        return
      }
      this.evectionInfo = res.datas;
      this.evectionParams = {
        evectionNo: res.datas.evectionNo,
        evectionType: res.datas.evectionType,
        explainDesc: res.datas.explainDesc,
        feeAffiliationId: res.datas.feeAffiliationId,
        feeAffiliationName: res.datas.feeAffiliationName, // 费用归属名称
        feeAffiliationType: res.datas.feeAffiliationType ? res.datas.feeAffiliationType : 1,
        // linkman: res.datas.linkman == null ? this.userInfo.realName : res.datas.linkman,
        // linkmanPhone: res.datas.linkmanPhone == null ? this.userInfo.phone : res.datas.linkmanPhone,
        reason: res.datas.reason,
        travelUserList: res.datas.travellerList ? res.datas.travellerList : [],
      };

      this.setStandard()

      this.travelUserList = res.datas.travellerList ? res.datas.travellerList : [];
      this.popSelectTravelUserList = res.datas.travellerList;
      // 获取火车票行程
      let trainList = res.datas.ticketBookingPageDetailsResponseList;
      this.ticketTrainList = trainList;
      // 获取机票行程
      let flightList = res.datas.flightDetail ? res.datas.flightDetail.orderList : [];
      this.flightList = flightList;
      // 获取酒店行程
      let hotelList = res.datas.todHotelOrderInterimList ? res.datas.todHotelOrderInterimList : [];
      this.hotelList = hotelList
    },

    // 获取公司所有员工
    async getDepartmentUser() {
      if (this.evectionType === 1 || this.evectionType === '1') return;
      let reduceDataFunc = (data) => {
        data.map((m, i) => {
          m.children = m.deptList;
          m.userList = m.staffList;
          delete m.deptList;
          delete m.staffList;
          if (m.userList && m.userList.length) {
            m.userList.forEach(value => {
              value.id = value.subUserId;
              value.name = value.subStaffName
            })
          }
          if (m.children && m.children.length > 0) {
            reduceDataFunc(m.children)
          }
        })
      };
      let res = await get_user_info();
      this.companyId = res.datas.companyId;
      this.userInfo = res.datas;
      let result = await consumer_department_getDepartmentUsers({companyId: this.userInfo.companyId});
      let cascadeList = result.datas;
      reduceDataFunc(cascadeList);
      let [err, customerResult] = await awaitWrap(consumer_web_externalCustomer_getExternalCustomerAll());
      if (err) {
        this.cascadeList = cascadeList;
        return
      }
      let customerList = customerResult.datas;
      customerList.forEach(value => {
        value.id = value.userId;
        value.name = value.customerName
      });
      let customerDept = {
        children: [],
        deptId: -1,
        deptLevel: 1,
        deptName: '外部客户',
        deptStatus: 1,
        parentId: 0,
        userList: []
      };
      customerDept.userList = customerList;
      cascadeList.push(customerDept);
      this.cascadeList = cascadeList;
    },
    // 关闭员工选择框
    close_travelList() {
      this.travelLVisible = false;
      // this.temporaryForm.staffIdList = this.form.staffIdList;
    },
    // 确定选择员工
    async commit_travelList() {
      if (this.travelUserCheckList.length <= 0) {
        this.$message.warning('至少选择一位出行人');
        return;
      }
      for (let i = 0, l = this.popSelectTravelUserList.length; i < l; i++) {
        if (this.travelUserCheckList.indexOf(this.popSelectTravelUserList[i].staffId) > -1) {
          this.evectionParams.travelUserList.push(this.popSelectTravelUserList[i])
        }
      }
      let travelUserIdList = [];
      this.evectionParams.travelUserList.forEach(item => {
        travelUserIdList.push(item.userId)
      });

      const params = {
        applyId: this.query.applyId,
        startDate: this.query.startDate,
        endDate: this.query.endDate,
        evectionNo: this.evectionParams ? this.evectionParams.evectionNo : '',
        evectionType: this.evectionParams ? this.evectionParams.evectionType : 1,
        reason: this.evectionParams ? this.evectionParams.reason : '',
        travellerList: travelUserIdList,
        personType: 2
      };
      let res = await consumer_evection_addOrUpdate(params);

      this.travelLVisible = false;
    },

    // 选择员工回调
    get_select_sttaff(val) {
      /*this.temporaryForm.staffList = val;*/
    },
    initCheckTravelBox() {
      let loading = '';
      this.$refs.checkTravelBox.init({
        checkFun: () => {
          let params = {
            evectionNo: this.evectionNo,
            motorcycleType: this.currentTrainInfo.motorcycleType,
            seatCode: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatCode,
            seatName: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatName
          };
          loading = this.$loading({
            lock: true,
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          return consumer_trains_order_scheduledToCheck(params).then(res => {
            let violationResults = res.datas.violationResults;
            loading.close();
            return violationResults // 返回一个数组给组件
            /* 数组每项包含以下内容
            * businessType: 业务类型:1.火车票;2.机票;3.酒店 integer(int32)
            * controlRules: 管控规则:1.允许预定,只做提示;2.选择原因,3.不允许预定,4不限 integer(int32)
            * seatName: 差标政策 string
            * standard: 差标类型 string
            * userId: 违反用户id integer(int64)
            * userRealName 违反用户名字 string
            * violationOfReason 预订信息 string
            * */
          }).catch(() => {
            loading.close();
            return null
          })
        },
        reserveSubmitFun: (warningList) => { // 返回的warningList数组里面的每一项会多出一个reason的字段，是存储选中的超标原因
          let buyTicketsType = 0;
          if (this.currentTrainInfo.isSale === '1' && parseInt(this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatInventory) > 0) {
            buyTicketsType = 0
          } else if (this.currentTrainInfo.isSale === '1' && parseInt(this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatInventory) <= 0) {
            buyTicketsType = 4
          } else {
            buyTicketsType = 5
          }

          let overproofApplyList = [];
          warningList.forEach(value => {
            let item = {
              businessType: 2,
              controlRule: value.controlRules,
              reason: value.reason ? value.reason : '',
              reserveContent: value.violationOfReason,
              standard: value.standard,
              standardContent: value.seatName,
              userId: value.userId,
              userName: value.userRealName,
            };
            overproofApplyList.push(item)
          });
          let [...robTicketNote] = this.currentTrainInfo.trainSeatVoList;
          let params = {
            controlRules: this.$refs.checkTravelBox.controlRules,
            acceptNoSeat: 1, // 是否接受无座: 1：接受 0：不接受
            fromStationCode: this.currentTrainInfo.fromStationCode,
            fromStationName: this.currentTrainInfo.fromStationName,
            fromTime: this.currentTrainInfo.fromTime,
            seatCode: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatCode,
            seatName: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatName,
            seatPrice: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatPrice,
            canChooseSeat: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].canChooseSeat === true ? 1 : 2,
            numberOfSeats: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].numberOfSeats,
            startDate: this.$moment(this.currentTrainInfo.trainStartDate).format('YYYY-MM-DD'),
            toStationCode: this.currentTrainInfo.toStationCode,
            toStationName: this.currentTrainInfo.toStationName,
            trainCode: this.currentTrainInfo.trainCode,
            arriveDate: this.$moment(this.currentTrainInfo.arriveDate).format('YYYY-MM-DD'),
            buyTicketsType: buyTicketsType, // 购票方式 0:自取票 1：送票上门2：12306 购票 4：抢票 5：预约抢票
            toTime: this.currentTrainInfo.toTime,
            trainNo: this.currentTrainInfo.trainNo,
            evectionNo: this.evectionNo,
            overproofApplyList: overproofApplyList,
            robTicketNote: JSON.stringify(robTicketNote),
            orderNo: this.orderNo,
            journeyId: parseInt(this.query.journeyId)
          };
          loading = this.$loading({
            lock: true,
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          return consumer_trains_order_addJourneyPC(params).then(res => {
            loading.close();
            return {
              evectionNo: res.datas.evectionNo
            }
          }).catch(() => {
            loading.close();
            return null
          })
        },
        reserveResultFun: (result) => { // 返回evectionNo
          if (!result) {
            return
          }
          //分离普通预订、抢票预订
          if (this.currentTrainInfo.isSale === '1' && parseInt(this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatInventory) > 0) {
            //普通预订
            this.$router.push({
              name: 'admin-travel-reserve',
              query: {
                trainOrderNo: this.trainOrderNo,
                evectionNo: result.evectionNo,
                evectionType: this.evectionType,
                redirectType: this.redirectType,
                motorcycleType: this.currentTrainInfo.motorcycleType,
              }
            })
          } else {
            //抢票预订、预约抢
            this.$router.push({
              name: 'admin-travel-grabbing',
              query: {
                evectionNo: result.evectionNo,
                evectionType: this.evectionType,
                redirectType: this.redirectType,
                motorcycleType: this.currentTrainInfo.motorcycleType,
              }
            })
          }

        }
      })
    },
    // 个人出差预定
    async setOrder() {
      let loading;
      loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let buyTicketsType = 0;
      if (this.currentTrainInfo.isSale === '1' &&
        parseInt(this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatInventory) > 0
      ) {
        buyTicketsType = 0
      } else if (this.currentTrainInfo.isSale === '1' &&
        parseInt(this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatInventory) <= 0
      ) {
        buyTicketsType = 4
      } else {
        buyTicketsType = 5
      }
      let [...robTicketNote] = this.currentTrainInfo.trainSeatVoList;

      let res = await consumer_trains_account_myAccount();

      if (res.datas.accountStatus == 2) {
        // 未授权12306--授权信息
        loading.close();
        this.$alert('根据铁路局规定，购买火车票必须要实名制，请先授权12306账号信息', '温馨提示', {
          confirmButtonText: '去授权12306',
        }).then(() => {
          this.$router.push({
            name: 'admin-train-account-depute'
          });
        });
        return
      } else if (res.datas.accountStatus == 3) {
        // 未核验手机号
        loading.close();
        this.$alert('您的12306账号需要核验绑定的手机号，请先完成手机号核验', '温馨提示', {
          confirmButtonText: '去核验手机号',
        }).then(() => {
          this.$router.push({
            name: 'admin-train-account-check'
          });
        });
        return
      } else if (res.datas.accountStatus == 4) {
        // 未授权12306--更改信息
        loading.close();
        this.$alert('您的12306账号，购票核验时提示账号密码变更，请重新授权变更后的账号', '温馨提示', {
          confirmButtonText: '去授权12306',
        }).then(() => {
          this.$router.push({
            name: 'admin-train-account-depute'
          });
        });
        return
      }

      let params = {
        controlRules: this.$refs.checkTravelBox.controlRules,
        acceptNoSeat: 0, // 是否接受无座: 1：接受 0：不接受
        fromStationCode: this.currentTrainInfo.fromStationCode,
        fromStationName: this.currentTrainInfo.fromStationName,
        fromTime: this.currentTrainInfo.fromTime,
        seatCode: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatCode,
        seatName: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatName,
        seatPrice: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatPrice,
        canChooseSeat: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].canChooseSeat === true ? 1 : 2,
        numberOfSeats: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].numberOfSeats,
        startDate: this.$moment(this.currentTrainInfo.trainStartDate).format('YYYY-MM-DD'),
        toStationCode: this.currentTrainInfo.toStationCode,
        toStationName: this.currentTrainInfo.toStationName,
        trainCode: this.currentTrainInfo.trainCode,
        arriveDate: this.$moment(this.currentTrainInfo.arriveDate).format('YYYY-MM-DD'),
        buyTicketsType: buyTicketsType, // 购票方式 0:自取票 1：送票上门2：12306 购票 4：抢票 5：预约抢票
        toTime: this.currentTrainInfo.toTime,
        trainNo: this.currentTrainInfo.trainNo,
        evectionNo: this.evectionNo,
        overproofApplyList: [],
        robTicketNote: JSON.stringify(robTicketNote),
        orderNo: this.orderNo,
        journeyId: parseInt(this.query.journeyId)
      };

      consumer_trains_order_addJourneyForPersonalPC(params).then(res => {
        loading.close();
        //分离普通预订、抢票预订
        if (buyTicketsType == 0) {
          this.$router.push({
            name: 'admin-travel-reserve',
            query: {
              fromStationCode: this.currentTrainInfo.fromStationCode,
              toStationCode: this.currentTrainInfo.toStationCode,
              trainOrderNo: this.trainOrderNo,
              evectionNo: res.datas.evectionNo,
              orderNo: res.datas.orderNo,
              evectionType: this.evectionType,
              redirectType: this.redirectType,
            }
          })
        } else {
          this.$router.push({
            name: 'admin-travel-grabbing',
            query: {
              evectionNo: res.datas.evectionNo,
              evectionType: this.evectionType,
              redirectType: this.redirectType,
            }
          })
        }

      }).catch(() => {
        loading.close()
      })
    },
    disabledDate(time) {
      let oneData = new Date(this.timeData[0]);
      let twoData = new Date(this.timeData[1]);

      if (this.timeData[0]) {
        let dateTest = new Date(this.timeData[0]);
        let dateBox = new Date();

        if (dateTest - dateBox <= 0) {
          oneData  = Date.now() - 8.64e7;
        } else {
          oneData  = new Date(this.timeData[0]);
        }
      } else {
        oneData = Date.now() - 8.64e7;
      }

      return (time.getTime() > twoData) || (time.getTime() < oneData)
    },
    setToday() {
      this.pickerTrainDate = this.$moment().locale('zh-cn').format('YYYY-MM-DD')
    },
    changeOnlySearchTrain(val) {
      if (val) {
        this.isAllTrain = false;
        this.checkTrainList = [1, 2] // 传入动车的数值
      } else {
        this.isAllTrain = true;
        this.checkTrainList = []
      }
      // 开始过滤
      this.trainList = this.trainFilter(this.oriTrainList)
    },
    get_travelPolicyTips() {
      const params = {
        businessType: 2,
        evectionNo: this.evectionNo
      };

      consumer_web_policyConfig_travelPolicyTips(params).then(res => {
        this.travelPolicy = res.datas.trainsOrHotelMsg;
      })
    },
    initEpidemicPolicy() {
      // 疫情防控政策提示--init
      this.$refs['epidemicPolicy'].init({
        startCityCode: this.params.fromStationCode,
        endCityCode: this.params.toStationCode
      })
    },
    getTrainList(code) {
      this.pickerTrainDate = this.pickerTrainDateTo;
      if (this.params.fromStationCode === '' || this.params.fromStationCode === undefined) {
        this.$message({
          message: '请先选择出发地',
          type: 'warning'
        });
        return
      }
      if (this.params.toStationCode === '' || this.params.toStationCode === undefined) {
        this.$message({
          message: '请先选择目的地',
          type: 'warning'
        });
        return
      }

      if (this.pickerTrainDate === '' || this.pickerTrainDate === null) {
        this.$message({
          message: '请先选择日期',
          type: 'warning'
        });
        return
      }
      this.params.trainDate = this.pickerTrainDate;
      if (this.params.trainDate === '' || this.params.trainDate === null) {
        this.$message({
          message: '请先选择日期',
          type: 'warning',
        });
        return
      }
      let loading;
      loading = this.$loading({
        lock: true,
        text: this.showLoading ? '页面停留超过10分钟，正在为您重新查询' : '',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      });
      consumer_trains_product_checkTheRemainingTrainTickets({
        evectionNo: this.evectionNo,
        applyId: this.applyId,
        ...this.params,
      }).then(res => {
        this.showLoading = false;
        this.oriTrainDate = this.params.trainDate;
        let resultList = res.resultList;
        this.oriTrainList = resultList;
        resultList.forEach(value => {
          value.timeTable = [];
          value.arriveDateText = this.$moment(value.arriveDate).format('YYYY-MM-DD hh:mm:ss')
        });
        this.trainList = this.trainFilter(this.oriTrainList);
        this.trainList.forEach((value,index) => {
          // console.log(value);
          this.$nextTick(() => {
            let params = {
              fromStationCode: value.fromStationCode,
              toStationCode: value.fromStationCode,
              trainCode: value.trainCode,
              trainDate: this.$moment(value.arriveDate).format("YYYY-MM-DD"),
            };
            this.$refs.aTrainTimetable[index].init(params);
          });
          value.trainSeatVoList.forEach(val => {
            val.trainParams = {
              evectionNo: this.evectionNo,
              motorcycleType: value.motorcycleType,
              seatName: val.seatName
            }
          })
        });
        this.DepartureTime = 1;
        this.trainListAll = this.trainList;
        this.oriFromStation = Object.assign({}, this.fromStation);
        this.oriToStation = Object.assign({}, this.toStation);

        let startStationList = [];
        let endStationList = [];
        this.oriTrainList.forEach(value => {
          value.timeTable = [];
          // 收集出发和目的地车站
          let startStationItem = {
            stationCode: value.fromStationCode,
            stationName: value.fromStationName
          };
          if (!JSON.stringify(startStationList).includes(JSON.stringify(startStationItem))) {
            startStationList.push(startStationItem)
          }
          let endStationItem = {
            stationCode: value.toStationCode,
            stationName: value.toStationName
          };
          if (!JSON.stringify(endStationList).includes(JSON.stringify(endStationItem))) {
            endStationList.push(endStationItem)
          }
        });
        this.application.startStationList = startStationList;
        this.application.endStationList = endStationList;
        this.setOutStationSelected = [];
        this.arriveStationSelected = [];

        this.setOutStationSelected.push({
          switch: false,
          text: '不限',
          fromStationCode: null,
        });
        this.application.startStationList.forEach((item, index) => {
          this.setOutStationSelected.push({
            switch: false,
            text: item.stationName,
            fromStationCode: item.stationCode,
          });
        });
        this.arriveStationSelected.push({
          switch: false,
          text: '不限',
          toStationCode: null,
        });
        this.application.endStationList.forEach((item, index) => {
          this.arriveStationSelected.push({
            switch: false,
            text: item.stationName,
            toStationCode: item.stationCode,
          });
        });

        loading.close();
        this.loadingNum++;
        if (code) {
          if (this.currentPolicyIndex !== null) {
            let switchBox = !this.trainList[this.currentPolicyIndex[0]].trainSeatVoList[this.currentPolicyIndex[1]].bookStatus;
            if (switchBox) {
            } else {
              this.reserve(this.trainList[this.currentPolicyIndex[0]],this.currentPolicyIndex[0],this.currentPolicyIndex[1]);
              this.currentPolicyIndex = null;
            }
          }
        }
      }).catch(() => {
        loading.close();
        this.showLoading = false;
        this.loadingNum++
      })
    },
    trainFilter(list) {
      let trainList = list;
      // 根据时间筛选 如果发车日期在一个月内，则不显示'预约抢'的车次，如果发车日期在一个月外，则不显示'预约和'抢票'
      /*let startDay = this.$moment()
      let searchDay = this.$moment(this.params.trainDate)
      this.daySpacing = searchDay.diff(startDay, 'days')
      let dayTrainItemList = []
      trainList.forEach(value => {
          if ((this.daySpacing + 1) === 29 || ((this.daySpacing + 1) <= 30 && value.isSale === '1') || ((this.daySpacing + 1) >= 30 && value.isSale === '0')) { // 预售期内且开售了 || 预售期外且没有开售
              dayTrainItemList.push(value)
          }
      })
      trainList = dayTrainItemList*/

      // 筛选列车类型
      if (!this.isAllTrain && this.checkTrainList.length > 0) {
        let trainItemList = [];
        this.checkTrainList.forEach(value => {
          trainList.forEach(val => {
            if (value === val.motorcycleType) {
              trainItemList.push(val)
            }
          })
        });
        trainList = trainItemList
      }

      // 筛选是否显示有票的
      if ((this.daySpacing + 1 <= 30) && this.isHasTicket) {
        let trainItemList = [];
        trainList.forEach(value => {
          let hasTicket = false;
          value.trainSeatVoList.forEach(val => {
            if (val.seatInventory > 0) {
              hasTicket = true
            }
          });
          if (hasTicket) {
            trainItemList.push(value)
          }
        });
        trainList = trainItemList
      }
      return trainList
    },
    showThisStationList(index, val) {
      if (val.timeTable.length > 0) {
        return
      }
      let params = {
        fromStationCode: val.fromStationCode,
        toStationCode: val.toStationCode,
        trainCode: val.trainCode,
        trainDate: this.$moment(val.trainStartDate).format('YYYY-MM-DD')
      };
      consumer_trains_common_queryRailwayTimetable(params).then(res => {
        let result = res.results;
        let listType = 0;
        for (let i = 0, l = result.length; i < l; i++) {
          if (result[i].stationName === val.fromStationName) {
            listType = 1;
            result[i].listType = 2;
            continue;
          }
          if (result[i].stationName === val.toStationName) {
            listType = 0;
            result[i].listType = 2;
            continue;
          }
          result[i].listType = listType
        }
        this.trainList[index].timeTable = result
      })
    },
    tableRowClassName({row, rowIndex}) {
      if (row.listType === 0) {
        return 'grey-line'
      } else if (row.listType === 1) {
        return 'default-line'
      } else {
        return 'active-line'
      }
    },
    changeFormAndTo() {
      let fromStation = this.toStation;
      let toStation = this.fromStation;
      let fromStationCode = this.params.toStationCode;
      let toStationCode = this.params.fromStationCode;
      this.fromStation = fromStation;
      this.toStation = toStation;
      this.params.fromStationCode = fromStationCode;
      this.params.toStationCode = toStationCode;
    },
    changeHasTicket(val) {
      if (this.oriTrainList.length === 0) {
        return;
      }
      this.trainList = this.trainFilter(this.oriTrainList)
    },
    async reserve(val, index, sIndex) {
      this.$refs.aAccountProcessStep.run()
        .then(res => {
          this.currentTrainInfo = val;
          this.currentTrainInfo.sIndex = sIndex;
          if (this.evectionType === 2) {
            //因公
            this.$refs.checkTravelBox.check({
              businessType: 2, // 机票1，火车票2，酒店3
              businessParams: {
                evectionNo: this.evectionNo,
                motorcycleType: this.currentTrainInfo.motorcycleType,
                seatName: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatName,
              }
            }).then(res => {
              let loading = null;

              let reserveSubmitFun = (warningList) => { // 返回的warningList数组里面的每一项会多出一个reason的字段，是存储选中的超标原因
                let buyTicketsType = 0;
                if (this.currentTrainInfo.isSale === '1' && parseInt(this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatInventory) > 0) {
                  buyTicketsType = 0
                } else if (this.currentTrainInfo.isSale === '1' && parseInt(this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatInventory) <= 0) {
                  buyTicketsType = 4
                } else {
                  buyTicketsType = 5
                }

                let [...robTicketNote] = this.currentTrainInfo.trainSeatVoList;
                let params = {
                  controlRules: this.$refs.checkTravelBox.controlRules,
                  acceptNoSeat: 1, // 是否接受无座: 1：接受 0：不接受
                  fromStationCode: this.currentTrainInfo.fromStationCode,
                  fromStationName: this.currentTrainInfo.fromStationName,
                  fromTime: this.currentTrainInfo.fromTime,
                  seatCode: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatCode,
                  seatName: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatName,
                  seatPrice: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatPrice,
                  canChooseSeat: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].canChooseSeat === true ? 1 : 2,
                  numberOfSeats: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].numberOfSeats,
                  startDate: this.$moment(this.currentTrainInfo.trainStartDate).format('YYYY-MM-DD'),
                  toStationCode: this.currentTrainInfo.toStationCode,
                  toStationName: this.currentTrainInfo.toStationName,
                  trainCode: this.currentTrainInfo.trainCode,
                  arriveDate: this.$moment(this.currentTrainInfo.arriveDate).format('YYYY-MM-DD'),
                  buyTicketsType: buyTicketsType, // 购票方式 0:自取票 1：送票上门2：12306 购票 4：抢票 5：预约抢票
                  toTime: this.currentTrainInfo.toTime,
                  trainNo: this.currentTrainInfo.trainNo,
                  evectionNo: this.evectionNo,
                  overproofApplyList: warningList,
                  robTicketNote: JSON.stringify(robTicketNote),
                  orderNo: this.orderNo,
                  journeyId: parseInt(this.query.journeyId)
                };
                loading = this.$loading({
                  lock: true,
                  spinner: 'el-icon-loading',
                  background: 'rgba(0, 0, 0, 0.7)'
                });
                consumer_trains_order_addJourneyPC(params).then(res => {
                  loading.close();
                  this.trainOrderNo = res.datas.orderNo;
                  this.evectionNo = res.datas.evectionNo;

                  //分离普通预订、抢票预订
                  if (this.currentTrainInfo.isSale === '1' && parseInt(this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatInventory) > 0) {
                    //普通预订
                    this.$router.push({
                      name: 'admin-train-reserve',
                      query: {
                        trainOrderNo: this.trainOrderNo,
                        evectionNo: this.evectionNo,
                        evectionType: this.evectionType,
                        redirectType: this.redirectType,
                        motorcycleType: this.currentTrainInfo.motorcycleType,
                      }
                    })
                  } else {
                    //抢票预订、预约抢
                    this.$router.push({
                      name: 'admin-train-grabbing',
                      query: {
                        trainOrderNo: this.trainOrderNo,
                        evectionNo: this.evectionNo,
                        evectionType: this.evectionType,
                        redirectType: this.redirectType,
                        motorcycleType: this.currentTrainInfo.motorcycleType,
                      }
                    })
                  }

                }).catch(() => {
                  loading.close();
                  return null
                });
              };
              // let reserveResultFun = (result) => { // 返回evectionNo
              //     if (!result) {
              //         return
              //     }
              //
              // };

              reserveSubmitFun(res.policies);
              // reserveResultFun(this.evectionNo);
            })
          } else {
            //因私
            this.setOrder();
          }
        })
        .catch(rej =>{
          //12306检测异常

        });
    },
    ticketGrabbing() {
    },
    clickDate(val) {
      this.params.trainDate = val;
      this.pickerTrainDate = val;
      this.getTrainList();
      // this.initEpidemicPolicy();
    },
    reloadTicket() {
      this.reloadTicketFun = setInterval(() => {
        this.reloadNum++;
        if (this.reloadNum === 10 * 60) {
          this.showLoading = true;
          this.getTrainList();
          // this.initEpidemicPolicy();
          // 页面停留超过10分钟，正在为您重新查询
          this.reloadNum = 0;
        }
      }, 1000)
    },

    changeSort() {
    },
    initCheckChangeTravelBox() {
      this.$refs.checkChangeTravelBox.init({
        checkFun: () => {
          let flightDate = []
          if (this.flightList.length > 0) {
            this.flightList.forEach(value => {
              let flightItem = {
                bottomPrice: '',
                cabinRank: value.cabinRank,
                depDate: value.segmentList[0].depDate,
                discount: value.segmentList[0].discount,
                price: value.segmentList[0].price,
                evectionNo: this.evectionNo,
                depTime: value.segmentList[0].depTime,
                rqData: value.segmentList[0]
                /*rqData: {
                    depCityPortCode: '', // 出发三字码
                    arrCityPortCode: '', // 到达三字码
                    depDate: '', // 出发日期
                }*/
              }
              flightDate.push(flightItem)
            })
          }
          let params = {
            evectionNo: this.evectionNo,
            userIds: this.newTravelUser,
            excessRequest: flightDate
          }
          this.loading = true
          return consumer_trains_order_orderScheduledCheck(params).then(res => {
            this.loading = false
            return res.datas.violationResults || [] // 因无超标人时返回的空数组现在变成null，所以需自传空数组
          }).catch(() => {
            this.loading = false
            return []
          })
        },
        reserveSubmitFun: (warningList) => {
          let overproofApplyList = []
          if (warningList.length === 0) {
            return new Promise(function (resolve) {
              resolve([])
            })
          }
          warningList.forEach(value => {
            let item = {
              businessType: value.businessType,
              controlRule: value.controlRules,
              orderNo: value.orderNo,
              reason: value.reason,
              reserveContent: value.violationOfReason,
              standard: value.standard,
              standardContent: value.seatName,
              userId: value.userId,
              userName: value.userRealName
            }
            overproofApplyList.push(item)
          })

          let params = {
            evectionNo: this.evectionNo,
            overproofApplyList
          }
          this.loading = true
          return consumer_journey_addOrUpdateOverproof(params).then(res => {
            this.loading = false
            return {}
          }).catch(() => {
            this.loading = false
          })
        },
        reserveResultFun: (result) => {
          if (!result) {
            return
          }
          this.travelUserList = JSON.parse(JSON.stringify(this.pendingTravelUserList))
          this.evectionParams.travelUserList = JSON.parse(JSON.stringify(this.pendingTravelUserList))
          // 清除报错提示
          if (result.length !== 0) {
            this.$refs['orderForm'].clearValidate('travelUserList')
          }
          this.saveInfo()

          // 如果有酒店订单
          if (this.evectionParams.travelUserList.length > 0) {
            this.reactHotelUser()
          }
        }
      })
    },
    reactHotelUser() {
      if (this.hotelList.length > 0) {
        this.hotelForm.roomNum = this.hotelList[0].roomNum
        // this.hotelForm.roomNum = 2
        let checkInPerson = []
        for (let i = 0, l = this.hotelForm.roomNum; i < l; i++) {
          checkInPerson.push('')
        }
        this.hotelForm.checkInPerson = checkInPerson

        // this.hotelUserList = [...this.evectionParams.travelUserList]
        this.hotelUserList = this.evectionParams.travelUserList.slice()
        this.hotelUserList.forEach(value => {
          value.disabled = false
        })
        console.log('h', this.hotelUserList)

        this.hotelUserVisible = true
      }
    },
    changeHotelUser(index, val) {
      console.log(index, val)
      if (val !== '') {
        this.hotelUserList.forEach(value => {
          if (value.userId === val || this.hotelForm.checkInPerson.indexOf(value.userId) > -1) {
            value.disabled = true
          } else {
            value.disabled = false
          }
        })
        this.$refs.reserveForm.clearValidate(`checkInPerson.${index}`)
      } else {
        console.log(this.hotelForm.checkInPerson)
        this.hotelUserList.forEach(value => {
          if (value.disabled && this.hotelForm.checkInPerson.indexOf(value.userId) === -1) {
            value.disabled = false
          }
        })
      }
    },
    commitHotelReserve() {
      this.$refs.reserveForm.validate((valid) => {
        if (valid) {
          let params = {
            checkInPerson: this.hotelForm.checkInPerson,
            orderNo: this.hotelList[0].orderNo
          }
          // params.orderNo = this.hotelList[0].orderNo
          consumer_tOdHotelOrderInterim_updateCheckInPerson(params).then(res => {
            this.$message({
              type: 'success',
              message: '入住安排保存成功'
            })
            this.hotelUserVisible = false
          }).catch(() => {

          })
        }
      })
    },
    validteCheckIn(rule, value, callback) {
      if (
        value === "" ||
        value === null ||
        value === undefined
      ) {
        callback(new Error("请选择入住人"));
      } else {
        callback();
      }
    },
    checkEvectionRequiredTimeCanEdit() {
      return (this.evectionDetailType === '001' && this.unlimitedJourney === 0)
      /*if (this.cityCanEdit) {
        return false
      } else {
        return true
      }*/
    },
    checkEvectionRequiredCityCanEdit() {
      return (this.evectionDetailType === '001' && this.unlimitedJourney === 0)
      /*if (this.cityCanEdit) {
        return false
      } else {
        return true
      }*/
    },
    checkEvectionRequired() {
      this.$message({
        type: "warning",
        message: '不能修改出差单既定行程'
      })
    },
    checkEvectionTimeRequired() {
      this.$message({
        type: "warning",
        message: '不能修改出差单既定行程时间'
      })
    },
  showRulePopup() {
    this.orderRuleDialogVisible = true;
  },
  }
}
